import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import teoTest from "assets/images/statii/teotest.jpg";
import { getAllStations } from "custom/api/stationApi";

const allCompetences = [
  { image: "two_wheeler", name: "Clasa I" },
  { image: "directions_car", name: "Clasa II" },
  { image: "local_shipping", name: "Clasa III" },
];

const getCompetences = (competences) => {
  switch (competences) {
    case 1:
      return [allCompetences[0]];
    case 3:
      return [allCompetences[1]];
    case 5:
      return [allCompetences[2]];
    case 4:
      return [allCompetences[0], allCompetences[1]];
    case 6:
      return [allCompetences[0], allCompetences[2]];
    case 8:
      return [allCompetences[1], allCompetences[2]];
    case 9:
      return [allCompetences[0], allCompetences[1], allCompetences[2]];
    default:
      return [];
  }
};

export default function LandingPage() {
  const [stations, setStations] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    getAllStations({ pageSize: 50, pageIndex: 0 }).then(({ count, data, pageCount }) => {
      setStations(data);
    });
  }, []);

  const stationsPreview = stations.map((station) => {
    const {
      stationId,
      // eslint-disable-next-line no-unused-vars
      createdAt,
      // eslint-disable-next-line no-unused-vars
      description,
      identifier,
      name,
      competences,
      address,
      // eslint-disable-next-line no-unused-vars
      photo,
      // eslint-disable-next-line no-unused-vars
      userId,
    } = station;
    return (
      <Grid item xs={12} md={6} xl={3} m={2} key={stationId}>
        <DefaultProjectCard
          image={teoTest}
          label={identifier}
          title={name}
          description={address}
          action={{
            type: "internal",
            route: `/statii/${stationId}`,
            color: "dark",
            label: "inspecteaza",
          }}
          authors={getCompetences(competences)}
        />
      </Grid>
    );
  });

  return (
    <MDBox p={2}>
      <Grid container spacing={6} justifyContent="center">
        {stationsPreview}
      </Grid>
    </MDBox>
  );
}
