export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const UNAUTHORIZED = "UNAUTHORIZED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_DETAILS_SUCCESS = "UPDATE_DETAILS_SUCCESS";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const LOAD_VEHICLECATEGORIES_SUCCESS = "LOAD_VEHICLECATEGORIES_SUCCESS";
export const CREATE_VEHICLECATEGORY_SUCCESS = "CREATE_VEHICLECATEGORY_SUCCESS";
export const UPDATE_VEHICLECATEGORY_SUCCESS = "UPDATE_VEHICLECATEGORY_SUCCESS";
export const DELETE_VEHICLECATEGORY_SUCCESS = "DELETE_VEHICLECATEGORY_SUCCESS";

export const LOAD_CARS_SUCCESS = "LOAD_CARS_SUCCESS";
export const SELECT_CAR_SUCCESS = "SELECT_CAR_SUCCESS";

export const CREATE_CAR_SUCCESS = "CREATE_CAR_SUCCESS";
export const UPDATE_CAR_SUCCESS = "UPDATE_CAR_SUCCESS";
export const DELETE_CAR_SUCCESS = "DELETE_CAR_SUCCESS";

export const LOAD_APPOINTMENTS_SUCCESS = "LOAD_APPOINTMENTS_SUCCESS";
export const SELECT_APPOINTMENT_SUCCESS = "SELECT_APPOINTMENT_SUCCESS";

export const LOAD_CLIENTS_SUCCESS = "LOAD_CLIENTS_SUCCESS";
export const SELECT_CLIENT_SUCCESS = "SELECT_CLIENT_SUCCESS";
