import React from "react";
import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";

function ErrorPage() {
  return (
    <MDBox
      variant="gradient"
      bgColor="none"
      borderRadius="lg"
      coloredShadow="error"
      mx={2}
      mt={-3}
      p={2}
      mb={1}
      textAlign="center"
    >
      <MDTypography variant="h1">404</MDTypography>
      <MDTypography variant="subtitle1">Pagina nu a putut fi gasita :(</MDTypography>
      <MDTypography variant="caption">Pare ca te-ai pierdut.</MDTypography>
    </MDBox>
  );
}

export default ErrorPage;
