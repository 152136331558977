/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import App from "App";
import { Provider as ReduxProvider } from "react-redux";
// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import moment from "moment-timezone";

import { store, persistor } from "./custom/redux/store";
import * as types from "./custom/redux/actions/actionTypes";
import client from "./custom/configs/client";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/ro";

moment.locale("ro");

client.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    // Do something with response error
    if (error?.response?.status === 401) {
      store.dispatch({
        type: types.UNAUTHORIZED,
        payload: error,
      });
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("root")
);
