import axios from "axios";
import { getAppBaseUrl } from "./environment";

const client = axios.create({
  baseURL: getAppBaseUrl(),
  timeout: 10000,
  headers: { Accept: "application/json" },
});

export const setAuthorizationHeader = (token) => {
  if (token) {
    client.defaults.headers.common.authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.authorization;
  }
};

export default client;
