import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function loadAppointments({ pageSize, pageIndex, filters, sortBy }) {
  const params = { page: pageIndex, limit: pageSize };
  if (filters && filters.length > 0) {
    for (const filter of filters) {
      // if (filter.id === "stationName") params[filter.id] = filter.value.label;
      // else
      params[filter.id] = filter.value;
    }
  }

  if (sortBy && sortBy.length > 0) {
    params.orderBy = sortBy[0].id;
    params.order = sortBy[0].desc ? "desc" : "asc";
  }

  return client({ url: "appointments", method: "GET", params })
    .then(handleResponse)
    .catch(handleError);
}

export function getAppointment(appointmentId) {
  return client({
    url: `appointments/${appointmentId}`,
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function importAppointments(data) {
  return client({
    url: "appointments/import",
    timeout: 120000,
    method: "POST",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveAppointment(data) {
  const { appointmentId } = data;
  return client({
    url: appointmentId ? `appointments/${appointmentId}` : "appointments",
    method: appointmentId ? "PUT" : "POST",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteAppointment(appointmentId) {
  return client({
    url: `appointments/${appointmentId}`,
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
