import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function loadCars({ pageSize, pageIndex, filters, sortBy }) {
  const params = { page: pageIndex, limit: pageSize };

  if (filters && filters.length > 0) {
    for (const filter of filters) {
      params[filter.id] = filter.value;
    }
  }

  if (sortBy && sortBy.length > 0) {
    params.orderBy = sortBy[0].id;
    params.order = sortBy.desc ? "desc" : "asc";
  }

  return client({ url: "cars", method: "GET", params }).then(handleResponse).catch(handleError);
}

export function getCar(carId) {
  return client({
    url: `cars/${carId}`,
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveCar(data) {
  const { carId } = data;
  return client({
    url: carId ? `cars/${carId}` : "cars",
    method: carId ? "PUT" : "POST",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteCar(carId) {
  return client({
    url: `cars/${carId}`,
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
