import React from "react";
import { toast } from "react-toastify";

// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Grid } from "@mui/material";
import { withNavigation, withParams } from "../../utils/hocs";
import { clientFormIsValid } from "../../utils/Helper";
import { loadClients, saveClient, deleteClient, getClient } from "../../api/clientApi";

import Spinner from "../common/Spinner";
import ClientForm from "./ClientForm";

class ManageClientsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { client: {}, loading: false };
  }

  componentDidMount() {
    this.fetchClient(this.props.params?.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params?.id !== prevProps.params?.id) {
      this.fetchClient(this.props.params.id);
    }
  }

  fetchClient(clientId) {
    if (!clientId) return;

    this.setState({ loading: true }, () =>
      getClient(clientId)
        .then((response) => {
          const client = response.data;

          this.setState({
            client,
            loading: false,
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // alert('Loading appointments failed' + error)
        })
    );
  }

  loadClient = (clientId) => {
    if (!clientId) {
      this.setState({
        client: {},
        loading: false,
      });
      return;
    }
    getClient(clientId).then((response) => {
      this.setState({
        client: response.data,
        loading: false,
      });
    });
  };

  handleClientChange = ({ name, value }) => {
    const { client } = this.state;
    this.setState({
      client: {
        ...client,
        [name]: value,
      },
    });
  };

  handleClientSave = (event) => {
    event.preventDefault();
    const { client } = this.state;
    const { navigate } = this.props;

    const clientErrors = clientFormIsValid(client);
    this.setState({ clientErrors });
    if (!(Object.keys(clientErrors).length === 0)) return;
    this.setState({ loading: true });

    const isNew = !client.clientId;

    saveClient(client)
      .then((response) => {
        const newClient = response.data;
        if (isNew) navigate(`/admin/clienti/${newClient.clientId}`);
        else
          this.setState({
            client: newClient,
            loading: false,
          });

        toast.success("Client salvat");
      })
      .catch((error) => {
        this.setState({
          clientErrors: { onSave: error.message, loading: false },
        });
      });
  };

  handleClientDelete = (event) => {
    event.preventDefault();
    const {
      client: { clientId },
    } = this.state;

    if (!clientId) {
      return null;
    }

    this.setState({ loading: true });

    deleteClient(clientId)
      .then(() => {
        toast.success("Client sters");
        this.handleClientChange({
          name: "client",
          value: { client: {} },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          clientErrors: { onSave: error.message },
        });
      });
  };

  loadClients = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "name", value: search });
    // eslint-disable-next-line no-unused-vars
    const { data, pageCount } = await loadClients({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const clients = [];

    for (const client of data) {
      clients.push({ value: client.clientId, label: client.name });
    }

    return {
      options: clients,
      hasMore: clients.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const { client, clientErrors, loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <ClientForm
              client={client}
              errors={clientErrors}
              onChange={this.handleClientChange}
              onSave={this.handleClientSave}
              onDelete={this.handleClientDelete}
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }
}

export default withParams(withNavigation(ManageClientsPage));
