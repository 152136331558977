import React from "react";
import "./CarsPage.css";
import { toast } from "react-toastify";
// @material-ui/icons

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { Grid, Icon } from "@mui/material";
import Swal from "sweetalert2";
import { withNavigation } from "custom/utils/hocs";
import { loadCars, deleteCar } from "../../api/carApi";

class CarsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadTableData: false,
      alert: null,
      cars: [],
    };
  }

  getColumns = () => [
    {
      Header: "Nr Inmatriculare",
      accessor: "registrationNumber",
    },
    {
      Header: "Nume",
      accessor: "name",
    },
    {
      Header: "Nr telefon",
      accessor: "phoneNumber",
    },
    {
      Header: "Marca",
      accessor: "brand",
    },
    {
      Header: "Adresa",
      accessor: "address",
    },
    {
      Header: "Categorie vehicul",
      accessor: "vehicleCategoryName",
    },
    {
      Header: "CIV",
      accessor: "vic",
    },
    {
      Header: "Sasiu",
      accessor: "vin",
    },
  ];

  deleteCar = (carId) => {
    this.setState({ alert: null }, () => {
      deleteCar(carId)
        .then(() => {
          this.setState(
            {
              reloadTableData: !this.state.reloadTableData,
            },
            () => {
              toast.success("Masina stearsa");
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
            carErrors: { onSave: error.message },
          });
        });
    });
  };

  deleteAlert = (carId) => {
    Swal.fire({
      title: "Sunteti sigur?",
      type: "warning",
      text: "O data stearsa, masina nu va mai putea fi recuperata!",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Da, sterge-o!",
      cancelButtonText: "Renunta",
      onOpen: () => {
        // code
      },
    }).then((result) => {
      // eslint-disable-next-line no-unused-vars
      const { isConfirmed, isDenied, isDismissed, dismiss, value } = result;
      if (value) {
        this.setState({ loading: true }, () => {
          this.deleteCar(carId);
        });
      }
    });
  };

  fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    this.setState({ loading: true });
    loadCars({ pageSize, pageIndex, filters, sortBy }).then((response) => {
      const { count, data, pageCount } = response;

      this.setState({
        cars: data,
        count,
        pageCount: pageCount || 0,
        loading: false,
      });
    });
  };

  onRowClick = ({ original: { carId } }) => {
    this.props.navigate(`/admin/masini/${carId}`);
  };

  render() {
    const { cars, loading, pageCount, count, reloadTableData } = this.state;

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={3}>
          <Grid container>
            <Card>
              <Grid item xs={12} lg={12}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between">
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Masini
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex">
                    <MDBox mr={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        iconOnly
                        onClick={() => this.props.navigate(`/admin/masina/`)}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>

              <MDBox mx={3}>
                <DataTable
                  table={{ columns: this.getColumns(), rows: cars }}
                  loading={loading}
                  fetchData={this.fetchData}
                  pageCount={pageCount}
                  count={count}
                  onClick={this.onRowClick}
                  forceReload={reloadTableData}
                />
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default withNavigation(CarsPage);
