import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Grid } from "@mui/material";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import DateInput from "custom/components/common/DateInput";
import { getChartData, getStats } from "../api/dashboardApi";

const monthLabels = [
  "Ian",
  "Feb",
  "Mar",
  "Apr",
  "Mai",
  "Iun",
  "Iul",
  "Aug",
  "Sep",
  "Oct",
  "Noi",
  "Dec",
];

const chartDefaultData = {
  labels: [],
  datasets: {
    label: "sad",
    data: [],
  },
};

export default function Dashboard() {
  const [chartData, setChartData] = useState(chartDefaultData);
  const [statsData, setStatsData] = useState({});
  const [fromDate, setFromDate] = useState("2019-09-01 00:00:00");
  const [toDate, setToDate] = useState("2022-12-31 23:59:00");

  const tryGetChartData = () => {
    const momentFrom = moment(fromDate);
    const momentTo = moment(toDate);
    if (momentTo.isAfter(momentFrom)) {
      const labels = [];
      const months = Math.ceil(momentTo.diff(momentFrom, "months", true));
      let firstMonth = momentFrom.month();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < months; i++) {
        labels.push(monthLabels[firstMonth]);
        // eslint-disable-next-line no-plusplus
        firstMonth++;
        if (firstMonth === 12) firstMonth = 0;
      }
      getChartData({
        fromDate,
        toDate,
      }).then(({ data }) => {
        if (data) {
          const { graph } = data;
          const datasetsData = [];
          graph.forEach((row) => {
            datasetsData.push(row.total);
          });
          const newChartData = JSON.parse(JSON.stringify(chartDefaultData));
          newChartData.labels = labels;
          newChartData.datasets.data = datasetsData;
          setChartData(newChartData);
        }
      });
    } else {
      toast.error("Prima data trebuie sa fie inainte de a 2 a!!!");
    }
  };

  useEffect(() => {
    tryGetChartData();
  }, [fromDate, toDate]);

  useEffect(() => {
    getStats({ fromDate, toDate }).then(({ data }) => {
      if (data) {
        const { stats } = data;
        setStatsData(stats);
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="event"
                title="Programari"
                count={statsData.appointmentsTotal}
                percentage={{
                  color: "dark",
                  amount: "!",
                  label: `Prima programare inregistrata - ${statsData.transactionDate}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="drive_eta"
                title="Masini"
                count={statsData.carsTotal}
                percentage={{
                  color: "success",
                  amount: "!",
                  label: `Prima masina inregistrata - ${statsData.registrationNumber}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="person"
                title="Clienti"
                count={statsData.clientsTotal}
                percentage={{
                  color: "info",
                  amount: "!",
                  label: `Primul client inregistrat - ${statsData.name}`,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <ReportsLineChart
              color="dark"
              title="Programari lunare"
              description=""
              chart={chartData}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DateInput
                    id="from"
                    value={fromDate}
                    label="De la"
                    name="fromDate"
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateInput
                    id="to"
                    value={toDate}
                    label="Pana la"
                    name="toDate"
                    onChange={(date) => {
                      setToDate(date);
                    }}
                  />
                </Grid>
              </Grid>
            </ReportsLineChart>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
