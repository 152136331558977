import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function appointments(state = initialState.appointments, action) {
  switch (action.type) {
    case types.SELECT_APPOINTMENT_SUCCESS:
      return { ...state, selectedAppointment: action.payload.data };
    case types.LOAD_APPOINTMENTS_SUCCESS:
      return action.payload;
    case types.DELETE_CAR_SUCCESS:
      return state.filter((appointment) => appointment.carId !== action.payload.data.carId);
    default:
      return state;
  }
}
