import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

export function withNavigation(Component) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    return <Component {...props} navigate={useNavigate()} />;
  };
}

export function withParams(Component) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    return <Component {...props} params={useParams()} />;
  };
}

export function withLocation(Component) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    return <Component {...props} location={useLocation()} />;
  };
}
