import { Autocomplete, CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import React from "react";

function SelectInputPaginate({
  name,
  label,
  onChange,
  placeholder,
  value,
  loadOptions,
  error,
  success,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = async (search) => {
    // eslint-disable-next-line no-unused-vars
    const { options, hasMore, additional } = await loadOptions(search, undefined, { page: 0 });
    setOptions(options);
  };

  return (
    <Autocomplete
      name={name}
      open={open}
      onOpen={() => {
        setOpen(true);
        if (value && value.value) {
          handleChange(value.label);
        } else {
          // handleChange(""); too many records to load TODO: implement async again, but with material-ui autocomplete, or react-select async with material ui style
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(ev, value) => onChange(value)}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      {...rest}
      renderInput={(params) => (
        <MDInput
          {...params}
          onChange={(ev) => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              handleChange(ev.target.value);
            }
          }}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error || ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SelectInputPaginate;
