import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function getAllStation(stationId) {
  return client({
    url: `stations/all/${stationId}`,
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllStations({ pageSize, pageIndex, filters, sortBy }) {
  const params = { page: pageIndex, limit: pageSize };

  if (filters && filters.length > 0) {
    for (const filter of filters) {
      params[filter.id] = filter.value;
    }
  }

  if (sortBy && sortBy.length > 0) {
    params.orderBy = sortBy[0].id;
    params.order = sortBy.desc ? "desc" : "asc";
  }

  return client({ url: "stations/all", method: "GET", params })
    .then(handleResponse)
    .catch(handleError);
}

export function loadStations({ pageSize, pageIndex, filters, sortBy }) {
  const params = { page: pageIndex, limit: pageSize };

  if (filters && filters.length > 0) {
    for (const filter of filters) {
      params[filter.id] = filter.value;
    }
  }

  if (sortBy && sortBy.length > 0) {
    params.orderBy = sortBy[0].id;
    params.order = sortBy.desc ? "desc" : "asc";
  }

  return client({ url: "stations", method: "GET", params }).then(handleResponse).catch(handleError);
}

export function getStation(stationId) {
  return client({
    url: `stations/${stationId}`,
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveStation(data) {
  const { stationId } = data;
  return client({
    url: stationId ? `stations/${stationId}` : "stations",
    method: stationId ? "PUT" : "POST",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteStation(stationId) {
  return client({
    url: `stations/${stationId}`,
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
