import React from "react";
import { toast } from "react-toastify";

// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Grid } from "@mui/material";
import { withNavigation, withParams } from "../../utils/hocs";
import { carFormIsValid, clientFormIsValid } from "../../utils/Helper";
import { loadVehicleCategories } from "../../api/vehicleCategoryApi";
import { loadCars, saveCar, deleteCar, getCar } from "../../api/carApi";
import { loadClients, saveClient, deleteClient, getClient } from "../../api/clientApi";

import Spinner from "../common/Spinner";
import ClientForm from "../client/ClientForm";
import CarForm from "./CarForm";

class ManageCarsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { car: {}, client: {} };
  }

  componentDidMount() {
    this.fetchCar(this.props.params?.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params?.id !== prevProps.params?.id) {
      this.fetchCar(this.props.params.id);
    }
  }

  fetchCar(carId) {
    if (!carId) return;

    this.setState({ loading: true }, () =>
      getCar(carId)
        .then((response) => {
          const car = response.data;
          const client = car.client ?? {};

          this.setState({
            car,
            client,
            loading: false,
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // alert('Loading appointments failed' + error)
        })
    );
  }

  loadClient = (clientId) => {
    if (!clientId) {
      this.setState({
        client: {},
        loading: false,
      });
      return;
    }
    getClient(clientId).then((response) => {
      this.setState({
        client: response.data,
        loading: false,
      });
    });
  };

  handleCarChange = ({ name, value }) => {
    const { car } = this.state;
    switch (name) {
      case "client": {
        this.setState(
          {
            car: {
              ...car,
              clientId: value.value,
              clientName: value.label,
            },
          },
          () => this.loadClient(value.value)
        );
        break;
      }
      case "multiClient": {
        const history = value.map(({ value, label }) => ({
          clientId: value,
          name: label,
        }));
        this.setState({
          car: {
            ...car,
            history,
          },
          loading: false,
        });
        break;
      }
      case "vehicleCategory":
        this.setState({
          car: {
            ...car,
            vehicleCategoryId: value.value,
            vehicleCategoryName: value.label,
          },
          loading: false,
        });
        break;
      default:
        this.setState({
          car: {
            ...car,
            [name]: value,
            loading: false,
          },
        });
        break;
    }
  };

  handleCarSave = (event) => {
    event.preventDefault();
    const { car } = this.state;
    const { navigate } = this.props;

    const carErrors = carFormIsValid(car);
    this.setState({ carErrors });
    if (!(Object.keys(carErrors).length === 0)) return;

    this.setState({ loading: true });

    const isNew = !car.carId;
    saveCar(car)
      .then((response) => {
        const newCar = response.data;
        if (isNew) navigate(`/admin/masini/${newCar.carId}`);
        else
          this.setState({
            car: newCar,
            loading: false,
          });

        toast.success("Masina salvata");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          carErrors: { onSave: error.message },
        });
      });
  };

  handleCarDelete = (event) => {
    event.preventDefault();
    const {
      car: { carId },
    } = this.state;

    if (!carId) {
      return null;
    }

    this.setState({ loading: true });

    deleteCar(carId)
      .then(() => {
        toast.success("Masina stearsa");

        this.handleAppointmentChange({
          name: "car",
          value: { car: {}, client: {}, vehicleCategory: {} },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          carErrors: { onSave: error.message },
        });
      });
  };

  handleClientChange = ({ name, value }) => {
    const { client } = this.state;
    this.setState({
      client: {
        ...client,
        [name]: value,
      },
    });
  };

  handleClientSave = (event) => {
    event.preventDefault();
    const { client, car } = this.state;

    const clientErrors = clientFormIsValid(client);
    this.setState({ clientErrors });
    if (!(Object.keys(clientErrors).length === 0)) return;
    this.setState({ loading: true });

    saveClient(client)
      .then((response) => {
        const newClient = response.data;
        this.setState({
          car: {
            ...car,
            clientId: newClient.clientId,
            clientName: newClient.name,
          },
          client: newClient,
          loading: false,
        });

        toast.success("Client salvat");
      })
      .catch((error) => {
        this.setState({
          clientErrors: { onSave: error.message, loading: false },
        });
      });
  };

  handleClientDelete = (event) => {
    event.preventDefault();
    const {
      client: { clientId },
    } = this.state;

    if (!clientId) {
      return null;
    }

    this.setState({ loading: true });

    deleteClient(clientId)
      .then(() => {
        toast.success("Client sters");
        this.handleCarChange({
          name: "client",
          value: { client: {} },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          clientErrors: { onSave: error.message },
        });
      });
  };

  loadCars = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "registrationNumber", value: search });
    // eslint-disable-next-line no-unused-vars
    const { data, pageCount } = await loadCars({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const cars = [];

    for (const car of data) {
      const label = car.name ? `${car.registrationNumber} - ${car.name}` : car.registrationNumber;

      cars.push({
        value: car.carId,
        label,
      });
    }

    return {
      options: cars,
      hasMore: cars.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  loadClients = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "name", value: search });
    // eslint-disable-next-line no-unused-vars
    const { data, pageCount } = await loadClients({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const clients = [];

    for (const client of data) {
      clients.push({ value: client.clientId, label: client.name });
    }

    return {
      options: clients,
      hasMore: clients.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  loadVehicleCategories = async () => {
    const { data } = await loadVehicleCategories();

    const vehicleCategories = [];

    for (const vehicleCategory of data) {
      vehicleCategories.push({
        value: vehicleCategory.vehicleCategoryId,
        label: vehicleCategory.vehicleCategoryName,
      });
    }

    return {
      options: vehicleCategories,
      hasMore: false,
    };
  };

  getColumns = () => [
    {
      Header: "Data tranzactiei",
      accessor: "transactionDate",
    },
    {
      Header: "Data expirare",
      accessor: "expirationDate",
    },
    {
      Header: "Serie folie",
      accessor: "inspectionCode",
    },
    {
      Header: "Prestatie",
      accessor: "appointmentTypeName",
    },
  ];

  render() {
    const { car, carErrors, client, clientErrors, loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <ClientForm
              client={client}
              errors={clientErrors}
              onChange={this.handleClientChange}
              onSave={this.handleClientSave}
              onDelete={this.handleClientDelete}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CarForm
              car={car}
              errors={carErrors}
              onChange={this.handleCarChange}
              onSave={this.handleCarSave}
              onDelete={this.handleCarDelete}
              loadClients={this.loadClients}
              loadVehicleCategories={this.loadVehicleCategories}
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }
}

export default withParams(withNavigation(ManageCarsPage));
