import React from "react";
import MDTypography from "components/MDTypography";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import TextInput from "../common/TextInput";
import SelectInputPaginate from "../common/SelectInputPaginate";

function CarForm({
  car,
  onSave,
  onChange,
  onDelete,
  saving = false,
  errors = {},
  loadClients,
  loadVehicleCategories,
}) {
  const getVehicleCategoryValue = () => {
    const { vehicleCategoryId, vehicleCategoryName } = car;
    if (!vehicleCategoryId) {
      return null;
    }

    return {
      value: vehicleCategoryId,
      label: vehicleCategoryName,
    };
  };

  const getClientValue = () => {
    const { clientId, clientName } = car;
    if (!clientId) {
      return null;
    }

    return {
      value: clientId,
      label: clientName,
    };
  };

  const getMultiClientValue = () => {
    const { history } = car;
    if (!history || history.length === 0) {
      return [];
    }

    return history.map(({ clientId, name }) => ({
      value: clientId,
      label: name,
    }));
  };

  const showDeleteAlert = () => {
    Swal.fire({
      title: "Sunteti sigur?",
      type: "warning",
      text: "O data stearsa, masina nu va mai putea fi recuperata!",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Da, sterge-o!",
      cancelButtonText: "Renunta",
      onOpen: () => {
        // code
      },
    }).then((result) => {
      // eslint-disable-next-line no-unused-vars
      const { isConfirmed, isDenied, isDismissed, dismiss, value } = result;
      if (value) {
        onDelete();
      }
    });
  };

  return (
    <Card>
      {/* {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )} */}
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {car?.carId ? "Editeaza masina" : "Adauga o masina noua"}
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectInputPaginate
              name="client"
              label="Proprietar"
              value={getClientValue()}
              placeholder="Selecteaza..."
              loadOptions={loadClients}
              onChange={(selectedValue) =>
                onChange({
                  name: "client",
                  value: selectedValue || {},
                })
              }
              error={errors.clientId}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectInputPaginate
              name="others"
              label="Altii"
              value={getMultiClientValue()}
              multiple
              placeholder="Selecteaza..."
              loadOptions={loadClients}
              onChange={(selectedValue) =>
                onChange({
                  name: "multiClient",
                  value: selectedValue || [],
                })
              }
              error={errors.history}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="registrationNumber"
              label="Nr inmatriculare"
              value={car.registrationNumber}
              onChange={(event) => onChange(event.target)}
              error={errors.registrationNumber}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="brand"
              label="Marca"
              value={car.brand}
              onChange={(event) => onChange(event.target)}
              error={errors.brand}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="year"
              label="An"
              value={car.year}
              onChange={(event) => onChange(event.target)}
              error={errors.year}
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <SelectInputPaginate
              name="vehicleCategory"
              label="Categorie vehicul"
              value={getVehicleCategoryValue()}
              placeholder="Selecteaza..."
              loadOptions={loadVehicleCategories}
              onChange={(selectedValue) =>
                onChange({
                  name: "vehicleCategory",
                  value: selectedValue || {},
                })
              }
              error={errors.vehicleCategoryId}
            />
          </Grid>

          <Grid item xs={12}>
            {" "}
            <TextInput
              name="vic"
              label="CIV"
              value={car.vic}
              onChange={(event) => onChange(event.target)}
              error={errors.vic}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="vin"
              label="Serie Sasiu"
              value={car.vin}
              onChange={(event) => onChange(event.target)}
              error={errors.vin}
            />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={2}
        >
          {car.carId && (
            <MDBox>
              <MDButton variant="gradient" color="dark" size="small" onClick={showDeleteAlert}>
                Sterge
              </MDButton>
            </MDBox>
          )}
          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" onClick={onSave}>
              {saving ? "Se salveaza..." : "Salveaza"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CarForm;
