const DEV_URL = "http://localhost";
const PRODUCTION_URL = "https://statie-itp.ro";

const BASE_URL_DEV = `${DEV_URL}:5001/api/v1/`;
const BASE_URL_PRODUCTION = `${PRODUCTION_URL}/api/v1/`;

export const getAppBaseUrl = () => {
  switch (process.env.REACT_APP_BUILD_FOR) {
    case "PRODUCTION":
      return BASE_URL_PRODUCTION;
    default:
      return BASE_URL_DEV;
  }
};
