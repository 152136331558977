import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function vehicleCategories(state = initialState.vehicleCategories, action) {
  switch (action.type) {
    case types.CREATE_VEHICLECATEGORY_SUCCESS:
      return [...state, { ...action.payload.data }];
    case types.UPDATE_VEHICLECATEGORY_SUCCESS:
      return state.map((vehicleCategory) =>
        vehicleCategory.id === action.payload.data.vehicleCategoryId
          ? action.payload.data
          : vehicleCategory
      );
    case types.LOAD_VEHICLECATEGORIES_SUCCESS:
      return action.payload.data;
    case types.DELETE_VEHICLECATEGORY_SUCCESS:
      return state.filter(
        (vehicleCategory) => vehicleCategory.id !== action.payload.data.vehicleCategoryId
      );
    default:
      return state;
  }
}
