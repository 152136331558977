/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

// Images
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import ErrorPage from "custom/components/error/ErrorPage";
import { setAuthorizationHeader } from "custom/configs/client";
import PrivateRoute from "PrivateRoute";
import PublicRoute from "PublicRoute";
import MDBox from "components/MDBox";
import ManageAppointmentsPage from "custom/components/appointment/ManageAppointmentsPage";
import AppointmentsPage from "custom/components/appointment/AppointmentsPage";
import SignInPage from "custom/components/login/SignInPage";
import CarsPage from "custom/components/car/CarsPage";
import ClientsPage from "custom/components/client/ClientsPage";
import StationsPage from "custom/components/station/StationsPage";
import ManageCarsPage from "custom/components/car/ManageCarsPage";
import ManageClientsPage from "custom/components/client/ManageClientsPage";
import ManageStationsPage from "custom/components/station/ManageStationsPage";
import UserProfilePage from "custom/pages/UserProfilePage";
import DashboardPage from "custom/pages/DashboardPage";
// import LandingPage from "custom/pages/LandingPage";
import StationsPreviewPage from "custom/pages/StationsPreviewPage";
import StationPreviewPage from "custom/pages/StationPreviewPage";

function App({ token }) {
  if (token) {
    setAuthorizationHeader(token);
  }
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // eslint-disable-next-line no-unused-vars
  const getRoutes = (routes) =>
    routes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        const element = !route.isPublic ? (
          <PrivateRoute isAuthenticated={token}>{route.component}</PrivateRoute>
        ) : (
          <PublicRoute>{route.component}</PublicRoute>
        );
        return <Route exact path={route.route} element={element} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <MDBox width="100%" height="100%" minHeight="100vh" sx={{ overflowX: "hidden" }}>
        <Routes>
          {/* {getRoutes(allRoutes)} */}
          <Route
            path="/"
            element={
              <PublicRoute isAuthenticated={token}>
                <StationsPreviewPage />
                {/* <LandingPage /> */}
              </PublicRoute>
            }
          />

          <Route
            path="statii/"
            element={
              <PublicRoute isAuthenticated={token}>
                <StationsPreviewPage />
              </PublicRoute>
            }
          />

          <Route
            path="statii/:id"
            element={
              <PublicRoute isAuthenticated={token}>
                <StationPreviewPage />
              </PublicRoute>
            }
          />

          <Route
            path="login/"
            element={
              <PublicRoute isAuthenticated={token}>
                <SignInPage />
              </PublicRoute>
            }
          />

          <Route
            exact
            path="admin/"
            element={
              <PrivateRoute isAuthenticated={token}>
                <DashboardPage />
              </PrivateRoute>
            }
          />

          <Route
            path="admin/profil"
            element={
              <PrivateRoute isAuthenticated={token}>
                <UserProfilePage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="admin/programari"
            element={
              <PrivateRoute isAuthenticated={token}>
                <AppointmentsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/programari/:id"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageAppointmentsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/programare/"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageAppointmentsPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="admin/masini"
            element={
              <PrivateRoute isAuthenticated={token}>
                <CarsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PublicRoute isAuthenticated={token}>
                <ErrorPage />
              </PublicRoute>
            }
          />

          <Route
            path="admin/masini/:id"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageCarsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/masina/"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageCarsPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="admin/clienti"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ClientsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="admin/clienti/:id"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageClientsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/client/"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageClientsPage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="admin/statii"
            element={
              <PrivateRoute isAuthenticated={token}>
                <StationsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="admin/statii/:id"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageStationsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="admin/statie/"
            element={
              <PrivateRoute isAuthenticated={token}>
                <ManageStationsPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </MDBox>

      <ToastContainer autoClose={2000} hideProgressBar />
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  token: state.user.token,
});

export default connect(mapStateToProps)(App);
