import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Card, Grid, Icon, Tooltip } from "@mui/material";
import teoTest from "assets/images/statii/teotest.jpg";
import { getAllStation } from "custom/api/stationApi";
import { withParams } from "custom/utils/hocs";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import MDAvatar from "components/MDAvatar";

const emptyStation = {
  name: "",
  description: "",
  phoneNumber: "",
  email: "",
  address: "",
  competences: [],
};

const allCompetences = [
  { image: "two_wheeler", name: "Clasa I" },
  { image: "directions_car", name: "Clasa II" },
  { image: "local_shipping", name: "Clasa III" },
];

const getCompetences = (competences) => {
  switch (competences) {
    case 1:
      return [allCompetences[0]];
    case 3:
      return [allCompetences[1]];
    case 5:
      return [allCompetences[2]];
    case 4:
      return [allCompetences[0], allCompetences[1]];
    case 6:
      return [allCompetences[0], allCompetences[2]];
    case 8:
      return [allCompetences[1], allCompetences[2]];
    case 9:
      return [allCompetences[0], allCompetences[1], allCompetences[2]];
    default:
      return [];
  }
};

function StationPreview({ params }) {
  const { id } = params;
  const [station, setStation] = useState(emptyStation);

  const [controller] = useMaterialUIController();
  // eslint-disable-next-line no-unused-vars
  const { darkMode } = controller;

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    getAllStation(id).then(({ data, success }) => {
      setStation(data);
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { description, identifier, name, photo, address, competences, phoneNumber, email } =
    station;

  const renderCompetences = getCompetences(competences).map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        alt={name}
        size="sm"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
        bgColor="dark"
      >
        <Icon>{media}</Icon>
      </MDAvatar>
    </Tooltip>
  ));

  // TODO: to delete
  // eslint-disable-next-line no-unused-vars
  const useless = () => (
    <>
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Detalii
        </MDTypography>
      </MDBox>
      <MDBox component="ul" m={0} pl={4} mb={2}>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
            La statiile ITP Teotest Auto din Caracal, timpul tau conteaza, iar noi actionam
            intotdeauna cu promptitudine si profesionalism. Efectuam I.T.P. la autoturisme si
            autoutilitare pana la 3,5 tone echipate cu motoare pe benzina, GPL, diesel si
            autovehicule cu tractiune 4×4 permanent. Avem in spate o echipa de profesionisti. Suntem
            intotdeauna prompti, punand clientul intotdeauna pe primul loc.
          </MDTypography>
        </MDBox>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
            Statiile de inspectie tehnica periodica Teotest Auto dispun de standuri moderne iar
            lucrarile ITP se executa sub licenta R.A.R. Daca ai nevoie sa faci inspectia tehnica
            periodica, programeaza-te acum la numarul de telefon: 0763-612.733.
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );

  return (
    <MDBox p={2}>
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={3}>
          <Card
            sx={{
              "&:hover .card-header": {
                transform: "translate3d(0, -50px, 0)",
              },
            }}
          >
            <MDBox
              position="relative"
              borderRadius="lg"
              mt={-3}
              mx={2}
              className="card-header"
              sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
            >
              <MDBox
                component="img"
                src={teoTest}
                alt="Product Image"
                borderRadius="lg"
                shadow="sm"
                width="100%"
                height="100%"
                position="relative"
                zIndex={10}
                mb={2}
              />
            </MDBox>
            <MDBox textAlign="center" pt={2} px={3}>
              <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
                {name}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" sx={{ mt: 1.5, mb: 1, px: 3 }} justifyContent="center">
              {renderCompetences}
            </MDBox>
            <MDBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              bgColor="transparent"
              borderRadius="lg"
              p={3}
            >
              <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    Adresa:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {address}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    Telefon:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {phoneNumber}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    Email:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium">
                      {email}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <MDBox>
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {name}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h4" color="text">
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
              <Icon>star</Icon>
            </MDTypography>
            {/* <MDBox mt={1}>
              <MDTypography variant="h6" fontWeight="medium">
                ITP CLASELE I - II - III
              </MDTypography>
            </MDBox> */}
            <MDBox mb={1}>
              <MDTypography variant="h5" fontWeight="medium">
                O echipa de profesionisti te astepta in fiecare moment pentru o inspectie tehnica
                periodica rapida la statiile ITP Teotest Auto din Caracal. Inspectia tehnica
                periodica (ITP) este o verificare tehnica a autovehiculului care atesta capacitatea
                acestuia de a participa in conditii de siguranta la trafic, iar noi o realizam
                ieftin si rapid in cadrul unitatii noastre ITP din Caracal.
              </MDTypography>
            </MDBox>

            <MDBox mt={3} mb={1} ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Acte necesare
              </MDTypography>
            </MDBox>
            <MDBox component="ol" m={0} pl={4} mb={2}>
              <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography
                  variant="body2"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  Certificat de inmatriculare al autovehiculului (talon) - original, sau după caz,
                  autorizatia provizorie de circulatie
                </MDTypography>
              </MDBox>
              <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography
                  variant="body2"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  Buletinul de identitate al conducatorului auto - original
                </MDTypography>
              </MDBox>
              <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography
                  variant="body2"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  Polita R.C.A. (Asigurare de Raspundere Civila Auto) valabila la data inspectiei -
                  original
                </MDTypography>
              </MDBox>
              <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography
                  variant="body2"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  Cartea de identitate a autovehiculului (CIV) - original - securizata de R.A.R
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDTypography variant="subtitle1" color="text" sx={{ mt: 1.5, mb: 1 }}>
              Lucrarile ITP se executa sub licenta R.A.R.
            </MDTypography>
            <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
              De buna functionare a unui autoturism depind vietile soferului si a pasagerilor. De
              aceea, comform legii orice posesor auto trebuie sa prezinte masina sa la un service
              autorizat pentru ITP. Mai exact, vorbim de inspectia tehnica periodica ce vizeaza
              verificare generala a masinii in vederea bunei functionari si a depistarii
              eventualelor probleme ce pot aparea.
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default withParams(StationPreview);
