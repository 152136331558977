import React from "react";
import { toast } from "react-toastify";
// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Grid } from "@mui/material";
import { withNavigation, withParams } from "../../utils/hocs";
import { stationFormIsValid } from "../../utils/Helper";

import { deleteStation, getStation, loadStations, saveStation } from "../../api/stationApi";
import Spinner from "../common/Spinner";
import StationForm from "./StationForm";

class ManageStationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { station: {} };
  }

  componentDidMount() {
    this.fetchStation(this.props.params?.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params?.id !== prevProps.params?.id) {
      this.fetchStation(this.props.params.id);
    }
  }

  fetchStation(stationId) {
    if (!stationId) return;

    this.setState({ loading: true }, () =>
      getStation(stationId)
        .then((response) => {
          const station = response.data;

          this.setState({
            station,
            loading: false,
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // alert('Loading appointments failed' + error)
        })
    );
  }

  loadStation = (stationId) => {
    if (!stationId) {
      this.setState({
        station: {},
        loading: false,
      });
      return;
    }
    getStation(stationId).then((response) => {
      this.setState({
        station: response.data,
        loading: false,
      });
    });
  };

  handleStationChange = ({ name, value }) => {
    const { station } = this.state;
    this.setState({
      station: {
        ...station,
        [name]: value,
      },
    });
  };

  handleStationSave = (event) => {
    event.preventDefault();
    const { station } = this.state;
    const { navigate } = this.props;

    const stationErrors = stationFormIsValid(station);
    this.setState({ stationErrors });
    if (!(Object.keys(stationErrors).length === 0)) return;
    this.setState({ loading: true });

    const isNew = !station.stationId;
    saveStation(station)
      .then((response) => {
        const newStation = response.data;

        if (isNew) navigate(`/admin/statii/${newStation.stationId}`);
        else
          this.setState({
            station: newStation,
            loading: false,
          });

        toast.success("Statie salvata");
      })
      .catch((error) => {
        this.setState({
          stationErrors: { onSave: error.message, loading: false },
        });
      });
  };

  handleStationDelete = (event) => {
    event.preventDefault();
    const {
      station: { stationId },
    } = this.state;

    if (!stationId) {
      return null;
    }

    this.setState({ loading: true });

    deleteStation(stationId)
      .then(() => {
        toast.success("Station sters");
        this.handleStationChange({
          name: "station",
          value: { station: {} },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          stationErrors: { onSave: error.message },
        });
      });
  };

  loadStations = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "name", value: search });

    // eslint-disable-next-line no-unused-vars
    const { data, pageCount } = await loadStations({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const stations = [];

    for (const station of data) {
      stations.push({ value: station.stationId, label: station.name });
    }

    return {
      options: stations,
      hasMore: stations.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const { station, stationErrors, loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <StationForm
              station={station}
              errors={stationErrors}
              onChange={this.handleStationChange}
              onSave={this.handleStationSave}
              onDelete={this.handleStationDelete}
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }
}

export default withParams(withNavigation(ManageStationsPage));
