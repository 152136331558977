import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function loadClients({ pageSize, pageIndex, filters, sortBy }) {
  const params = { page: pageIndex, limit: pageSize };

  if (filters && filters.length > 0) {
    for (const filter of filters) {
      params[filter.id] = filter.value;
    }
  }

  if (sortBy && sortBy.length > 0) {
    params.orderBy = sortBy[0].id;
    params.order = sortBy.desc ? "desc" : "asc";
  }

  return client({ url: "clients", method: "GET", params }).then(handleResponse).catch(handleError);
}

export function getClient(clientId) {
  return client({
    url: `clients/${clientId}`,
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveClient(data) {
  const { clientId } = data;
  return client({
    url: clientId ? `clients/${clientId}` : "clients",
    method: clientId ? "PUT" : "POST",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteClient(clientId) {
  return client({
    url: `clients/${clientId}`,
    method: "DELETE",
  })
    .then(handleResponse)
    .catch(handleError);
}
