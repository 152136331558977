import MDInput from "components/MDInput";
import React from "react";

function TextInput({ name, label, onChange, placeholder, value, error, success, white, ...rest }) {
  return (
    <MDInput
      fullWidth
      name={name}
      value={value || ""}
      onChange={onChange}
      {...rest}
      label={label}
      error={!!error}
      helperText={error || ""}
    />
  );
}

export default TextInput;
